import React, { useState, useMemo, useEffect } from 'react';
import { Upload, Select, Button, message, Tree } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { urlAtlas as url } from "../ config";
import { useDebouncedCallback } from 'use-debounce';

const { Dragger } = Upload;
const { Option } = Select;

const baseTypes = [
  { id: '1', name: 'узел связи ядровой' },
  { id: '2', name: 'узел связи промежуточный' },
  { id: '3', name: 'узел связи стороние операторы' }
];

// Константа с типами прокладки
const ROUTING_TYPES = [
  { id: '1', name: 'воздух' },
  { id: '2', name: 'земля' },
  { id: '3', name: 'грунт' }
];

// В стилях компонента добавим константы для ширины
const COLUMN_STYLES = {
  nameColumn: { width: 300 },
  categoryColumn: { width: 200 },
  typeColumn: { width: 250 }
};

const ImportLayerPage = () => {
  const [file, setFile] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [kmzContent, setKmzContent] = useState(null);
  const [layers, setLayers] = useState([]);
  const [cities, setCities] = useState([]);
  const [pointTypes, setPointTypes] = useState({});
  const [lineTypes, setLineTypes] = useState({});
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [treeHeight, setTreeHeight] = useState(0);
  const [types, setTypes] = useState({});
  const [pointCategories, setPointCategories] = useState({});
  const [pointSubTypes, setPointSubTypes] = useState({});
  const [selectedRoutingTypes, setSelectedRoutingTypes] = useState({});
  const [searchText, setSearchText] = useState();
  const [searchTextCity, setSearchTextCity] = useState();
  const [importing, setImporting] = useState(false);

  const debouncedSearchLayer = useDebouncedCallback((text) => {
    fetch(`${url}/layers/search?search=` + text, {
      method: "GET",
    })
      .then(async (res) => setLayers(await res.json()))
      .catch((err) => {
        message.error("Something went wrong!");
      });
  }, 1000);

  const debouncedSearchCity = useDebouncedCallback((text) => {
    fetch(`${url}/layers/cities?search=` + text, {
      method: "GET",
    })
      .then(async (res) => setCities(await res.json()))
      .catch((err) => {
        message.error("Something went wrong!");
      });
  }, 1000);

  useEffect(() => {
    debouncedSearchLayer(searchText);
  }, [searchText]);

  useEffect(() => {
    debouncedSearchCity(searchTextCity);
  }, [searchTextCity]);

  useEffect(() => {
    const calculateHeight = () => {
      const windowHeight = window.innerHeight;
      setTreeHeight(windowHeight * 0.6); // 60% от высоты окна
    };
    getTypes();
    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  // Загрузка слоев и городов как в ExportLayerPage

  const handleFileUpload = async (file) => {
    try {
      setFile(file);
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${url}/layers/parse-kmz`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const content = await response.json();
        setKmzContent(content);
        // Устанавливаем ключи для раскрытия только документов верхнего уровня
        setExpandedKeys(content.documents.map((_, idx) => `doc-${idx}`));
        message.success('KMZ файл успешно загружен');
      } else {
        message.error('Ошибка при загрузке файла');
      }
    } catch (error) {
      message.error('Ошибка при обработке файла');
    }
  };

  const getTypes = async () => {
    const response = await fetch(`${url}/layers/types`);
    const data = await response.json();
    setTypes(data);
  };

  const handleImport = async () => {
    if (!selectedLayer || !selectedCity || !kmzContent) {
      message.error('Выберите слой, город и загрузите файл');
      return;
    }

    // Проверяем, что все точки имеют категорию и тип
    const hasUnsetPoints = kmzContent.documents.some(doc =>
      doc.points?.some(point =>
        !pointCategories[point.style] || !pointSubTypes[point.style]
      )
    );

    // Проверяем, что все линии имеют тип кабеля и тип прокладки
    const hasUnsetLines = kmzContent.documents.some(doc =>
      doc.lines?.some(line =>
        !lineTypes[line.style] || !selectedRoutingTypes[line.style]
      )
    );

    if (hasUnsetPoints || hasUnsetLines) {
      message.error('Пожалуйста, выберите типы для всех объектов');
      return;
    }

    try {
      setImporting(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('layerId', selectedLayer);
      formData.append('cityId', selectedCity);
      formData.append('content', JSON.stringify({
        documents: kmzContent.documents.map(doc => ({
          name: doc.name,
          points: doc.points.map(point => ({
            style: point.style,
            category: pointCategories[point.style],
            subType: pointSubTypes[point.style],
            count: point.count
          })),
          lines: doc.lines.map(line => ({
            style: line.style,
            cableType: lineTypes[line.style],
            routingType: selectedRoutingTypes[line.style],
            count: line.count
          }))
        }))
      }));

      const response = await fetch(`${url}/layers/import-kmz`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        message.success('Данные успешно импортированы');
      } else {
        message.error('Ошибка при импорте данных');
      }
    } catch (error) {
      message.error('Ошибка при импорте: ' + error.message);
    } finally {
      setImporting(false);
    }
  };

  const handlePointTypeChange = (style, value) => {
    setPointTypes(prev => ({
      ...prev,
      [style]: value
    }));
  };

  const handleLineTypeChange = (style, value) => {
    setLineTypes(prev => ({
      ...prev,
      [style]: value
    }));
  };

  const handleRoutingTypeChange = (style, value) => {
    setSelectedRoutingTypes(prev => ({
      ...prev,
      [style]: value
    }));
  };

  const pointCategoryOptions = [
    { value: 'node', label: 'Узел связи' },
    { value: 'apk', label: 'АПК' },
    { value: 'object', label: 'Объекты' }
  ];

  const getPointTypeOptions = (category) => {
    switch (category) {
      case 'node':
        return baseTypes.map(type => ({
          value: type.id,
          label: type.name
        }));
      case 'apk':
        return types.apkTypes.map(type => ({
          value: type.id,
          label: type.name
        }));
      case 'object':
        return types.objectTypes.map(type => ({
          value: type.id,
          label: type.name
        }));
      default:
        return [];
    }
  };

  const treeData = useMemo(() => {
    if (!kmzContent || !kmzContent.documents) return [];

    return kmzContent.documents.map((doc, docIndex) => {
      const children = [];

      if (doc.points && doc.points.length > 0) {
        children.push({
          title: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <span style={COLUMN_STYLES.nameColumn}>{`Точечные объекты (${doc.points.length})`}</span>
              {expandedKeys.includes(`points-${docIndex}`) && (
                <>
                  <Select
                    style={COLUMN_STYLES.categoryColumn}
                    placeholder="Категория для всех"
                    onChange={(value) => {
                      doc.points.forEach(point => {
                        setPointCategories(prev => ({
                          ...prev,
                          [point.style]: value
                        }));
                        setPointSubTypes(prev => ({
                          ...prev,
                          [point.style]: undefined
                        }));
                      });
                    }}
                    options={pointCategoryOptions}
                    onClick={e => e.stopPropagation()}
                  />
                  <Select
                    style={COLUMN_STYLES.typeColumn}
                    placeholder="Тип для всех"
                    disabled={!doc.points.every(point => pointCategories[point.style])}
                    onChange={(value) => {
                      doc.points.forEach(point => {
                        setPointSubTypes(prev => ({
                          ...prev,
                          [point.style]: value
                        }));
                      });
                    }}
                    options={getPointTypeOptions(doc.points[0] ? pointCategories[doc.points[0].style] : null)}
                    onClick={e => e.stopPropagation()}
                  />
                </>
              )}
            </div>
          ),
          key: `points-${docIndex}`,
          children: doc.points.map((point, index) => ({
            title: (
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', ...COLUMN_STYLES.nameColumn }}>
                  <div style={{
                    backgroundColor: point.color,
                    width: 12,
                    height: 12,
                    borderRadius: '50%'
                  }} />
                  <span>{`${point.style} (${point.count})`}</span>
                </div>
                {expandedKeys.includes(`points-${docIndex}`) && (
                  <>
                    <Select
                      style={COLUMN_STYLES.categoryColumn}
                      value={pointCategories[point.style]}
                      onChange={(value) => {
                        setPointCategories(prev => ({
                          ...prev,
                          [point.style]: value
                        }));
                        setPointSubTypes(prev => ({
                          ...prev,
                          [point.style]: undefined
                        }));
                      }}
                      options={pointCategoryOptions}
                      placeholder="Категория"
                      onClick={e => e.stopPropagation()}
                    />
                    <Select
                      style={COLUMN_STYLES.typeColumn}
                      value={pointSubTypes[point.style]}
                      onChange={(value) => setPointSubTypes(prev => ({
                        ...prev,
                        [point.style]: value
                      }))}
                      options={getPointTypeOptions(pointCategories[point.style])}
                      placeholder="Тип объекта"
                      disabled={!pointCategories[point.style]}
                      onClick={e => e.stopPropagation()}
                    />
                  </>
                )}
              </div>
            ),
            key: `point-${docIndex}-${index}`,
          }))
        });
      }

      if (doc.lines && doc.lines.length > 0) {
        children.push({
          title: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <span style={COLUMN_STYLES.nameColumn}>{`Линейные объекты (${doc.lines.length})`}</span>
              {expandedKeys.includes(`lines-${docIndex}`) && (
                <>
                  <Select
                    style={COLUMN_STYLES.categoryColumn}
                    placeholder="Тип кабеля для всех"
                    onChange={(value) => {
                      doc.lines.forEach(line => {
                        handleLineTypeChange(line.style, value);
                      });
                    }}
                    options={types.cableTypes.map(type => ({
                      value: type.id,
                      label: type.name
                    }))}
                    onClick={e => e.stopPropagation()}
                  />
                  <Select
                    style={COLUMN_STYLES.typeColumn}
                    placeholder="Тип прокладки для всех"
                    onChange={(value) => {
                      doc.lines.forEach(line => {
                        handleRoutingTypeChange(line.style, value);
                      });
                    }}
                    options={ROUTING_TYPES.map(type => ({
                      value: type.id,
                      label: type.name
                    }))}
                    onClick={e => e.stopPropagation()}
                  />
                </>
              )}
            </div>
          ),
          key: `lines-${docIndex}`,
          children: doc.lines.map((line, index) => ({
            title: (
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', ...COLUMN_STYLES.nameColumn }}>
                  <div style={{
                    backgroundColor: line.color,
                    width: 20,
                    height: 3
                  }} />
                  <span>{`${line.style} (${line.count})`}</span>
                </div>
                {expandedKeys.includes(`lines-${docIndex}`) && (
                  <>
                    <Select
                      style={COLUMN_STYLES.categoryColumn}
                      value={lineTypes[line.style]}
                      onChange={(value) => handleLineTypeChange(line.style, value)}
                      options={types.cableTypes.map(type => ({
                        value: type.id,
                        label: type.name
                      }))}
                      placeholder="Тип кабеля"
                      onClick={e => e.stopPropagation()}
                    />
                    <Select
                      style={COLUMN_STYLES.typeColumn}
                      value={selectedRoutingTypes[line.style]}
                      onChange={(value) => handleRoutingTypeChange(line.style, value)}
                      options={ROUTING_TYPES.map(type => ({
                        value: type.id,
                        label: type.name
                      }))}
                      placeholder="Тип прокладки"
                      onClick={e => e.stopPropagation()}
                    />
                  </>
                )}
              </div>
            ),
            key: `line-${docIndex}-${index}`,
          }))
        });
      }

      return {
        title: doc.name || `Документ ${docIndex + 1}`,
        key: `doc-${docIndex}`,
        children
      };
    });
  }, [kmzContent, pointCategories, pointSubTypes, lineTypes, selectedRoutingTypes, expandedKeys]);

  return (
    <div style={{ padding: '24px' }}>
      <Select
        showSearch
        style={{ width: 400, marginRight: 16 }}
        value={selectedLayer}
        placeholder={"Название или категорию слоя"}
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={searchText?.length ? undefined : null}
        onChange={setSelectedLayer}
        onSearch={setSearchText}
        options={(layers || []).map((layer) => ({
          value: layer.id,
          label: `${layer.category} - ${layer.name}`,
        }))}
      />

      <Select
        showSearch
        style={{ width: 300, marginBottom: 16 }}
        placeholder="Выберите город"
        value={selectedCity}
        defaultActiveFirstOption={false}
        onChange={setSelectedCity}
        onSearch={setSearchTextCity}
        filterOption={false}
        notFoundContent={searchTextCity?.length ? undefined : null}
        options={(cities || []).map((city) => ({
          value: city.id,
          label: `${city.name}`,
        }))}
      />

      <Dragger
        beforeUpload={(file) => {
          handleFileUpload(file);
          return false;
        }}
        accept=".kmz"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Нажмите или перетащите KMZ файл для загрузки
        </p>
      </Dragger>

      {kmzContent && kmzContent.documents.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Tree
            treeData={treeData}
            virtual
            height={treeHeight}
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
          />

          <Button
            type="primary"
            onClick={handleImport}
            loading={importing}
            style={{ marginTop: 16 }}
          >
            Импортировать
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImportLayerPage;