import { Tabs } from "antd";
import "./App.css";
import LinesPage from "./lines/LinesPage";
import ModerateVideosPage from "./videos/ModerateVideosPage";
import AllVideosPage from "./videos/AllVideosPage";
import LogoWithText from "./assets/LogoWithText";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import LinesVideosPage from "./videos/LinesVideosPage";
import ExportLayerPage from "./layers/ExportLayerPage";
import LoginPage from "./auth/LoginPage";
import {
  AuthContext,
  AuthIsNotSignedIn,
  AuthIsSignedIn,
  AuthProvider,
} from "./context/auth";
import { BrowserRouter as Router } from "react-router-dom";
import { useContext } from "react";
import Keys from "./pages/Keys";
import ImportLayerPage from "./layers/ImportLayerPage";


const Menu = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { changeToken, role } = useContext(AuthContext);

  const logout = () => {
    changeToken(undefined, undefined);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    window.location.reload();
  };

  return (
    <div className="App">
      <Tabs
        activeKey={location.pathname}
        onChange={(path) => {
          if (path === "/exit") {
            logout();
            return;
          }
          navigate(`${path}`);
        }}
        tabPosition={"left"}
        style={{ minHeight: "100vh" }}
        items={
          role === "key_manager"
            ? [{
              label: `Ключи`,
              key: "/keys",
            },
            {
              label: `Выйти`,
              key: "/exit",
            },] : role === "layer_manager" ? [{
              label: `Экспортировать слой`,
              key: "/layer",
            },
            {
              label: `Импортировать слой`,
              key: "/import",
            },
            {
              label: `Выйти`,
              key: "/exit",
            },] : [
              {
                label: `На согласование`,
                key: "/moderate",
              },
              {
                label: `Трассы`,
                key: "/lines",
              },
              {
                label: `Все файлы`,
                key: "/videos",
              },


              {
                label: `Выйти`,
                key: "/exit",
              },
            ]}
      />
      <div style={{ width: "calc(100vw - 220px)" }}>
        <LogoWithText />
        {children}
      </div>
    </div>
  );
};

const AppRoutes = () => {
  const { role } = useContext(AuthContext);
  return (
    <Routes>
      {role === "key_manager" ? (
        <>
          <Route path="/keys" element={<Keys />} />
        </>
      ) : role === "layer_manager" ? (
        <>
          <Route path="/layer" element={<ExportLayerPage />} />
          <Route path="/import" element={<ImportLayerPage />} />
        </>
      ) : (
        <>
          <Route path="/moderate" element={<ModerateVideosPage />} />
          <Route path="/lines" element={<LinesPage />} />
          <Route path="/videos" element={<AllVideosPage />} />
          <Route path="/line/:id" element={<LinesVideosPage />} />
        </>
      )}
      <Route path="/*" element={<Navigate replace to={role === "key_manager" ? "/keys" : role === "layer_manager" ? "/layer" : "/lines"} />} />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <AuthIsSignedIn>
        <Router>
          <Menu>
            <AppRoutes />
          </Menu>
        </Router>
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <Router>
          <Routes>
            <Route path={"/"} element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/*" element={<Navigate replace to={"/login"} />} />
          </Routes>
        </Router>
      </AuthIsNotSignedIn>
    </AuthProvider>
  );
}

export { App };
