import {
  Button,
  Select,
  message,
  Tree,
  Checkbox,
} from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { urlAtlas as url } from "../ config";
import { useDebouncedCallback } from "use-debounce";


const baseTypes = [
  { id: '1', name: 'узел связи ядровой' },
  { id: '2', name: 'узел связи промежуточный' },
  { id: '3', name: 'узел связи стороние операторы' }
];

const routingTypes = [
  { id: '1', name: 'воздух' },
  { id: '2', name: 'земля' },
  { id: '3', name: 'грунт' }
];

const ExportLayerPage = () => {
  const [layers, setLayers] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchText, setSeacrhText] = useState();
  const [selectedLayer, setSelectedLayer] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [layerComponents, setLayerComponents] = useState();

  // Состояния для выбранных компонентов
  const [selectedApks, setSelectedApks] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [selectedBaseStations, setSelectedBaseStations] = useState([]);
  const [selectedAntennas, setSelectedAntennas] = useState(false);
  const [selectedPipestands, setSelectedPipestands] = useState(false);

  const [exporting, setExporting] = useState(false);
  const [loadingComponents, setLoadingComponents] = useState(false);

  const debouncedSearchLayer = useDebouncedCallback((text) => {
    fetch(`${url}/layers/search?search=` + text, {
      method: "GET",
    })
      .then(async (res) => setLayers(await res.json()))
      .catch((err) => {
        message.error("Something went wrong!");
      });
  }, 1000);

  const getLayerCities = async () => {
    fetch(`${url}/layers/cities/${selectedLayer}`, {
      method: "GET",
    })
      .then(async (res) => {
        const result = await res.json();
        if (result.length) setCities(result);
        else message.warning("Нет городов у данного слоя");
      })
      .catch((err) => {
        message.error("Something went wrong!");
      });
  };

  const getLayerComponentsByCity = async () => {
    try {
      setLoadingComponents(true);
      const response = await fetch(
        `${url}/layers/components/${selectedLayer}?${selectedCity
          ?.map((id) => `cityId=${id}`)
          .join("&")}`,
        {
          method: "GET",
        }
      );
      const result = await response.json();
      setLayerComponents(result);
    } catch (err) {
      message.error("Something went wrong!");
    } finally {
      setLoadingComponents(false);
    }
  };

  const handleExport = async () => {
    try {
      setExporting(true);
      // Формируем объект с выбранными компонентами
      const selectedComponents = {
        apks: layerComponents?.apks.filter(apk =>
          selectedApks.includes(apk.ref_apk_type_id)
        ),

        fiberObjects: layerComponents?.fiberObjects.filter(obj =>
          selectedObjects.includes(obj.objectTypeId)
        ),

        lines: Object.entries(layerComponents?.lines || {}).reduce((acc, [type, lineGroup]) => {
          const selectedLinesForType = lineGroup.children.filter(line =>
            selectedLines.includes(`${type}-${line.cableTypeId}`)
          );
          if (selectedLinesForType.length > 0) {
            acc[type] = {
              type: parseInt(type),
              children: selectedLinesForType
            };
          }
          return acc;
        }, {}),

        baseStations: layerComponents?.baseStations.filter(bs =>
          selectedBaseStations.includes(bs.base_type)
        ),

        antennas: selectedAntennas ? layerComponents?.antennas : [],
        pipestands: selectedPipestands ? layerComponents?.pipestands : []
      };

      console.log({
        layerId: selectedLayer,
        cityIds: selectedCity,
        components: selectedComponents
      });

      const response = await fetch(`${url}/layers/export`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          layerId: selectedLayer,
          cityIds: selectedCity,
          components: selectedComponents
        })
      });

      if (response.ok) {
        const blob = await response.blob();

        // Создаем ссылку для скачивания
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'export.zip'; // Имя файла при скачивании

        // Добавляем ссылку в DOM и эмулируем клик
        document.body.appendChild(link);
        link.click();

        // Очищаем
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);

        message.success('Экспорт успешно завершен');
      } else {
        message.error('Ошибка при экспорте');
      }
    } catch (error) {
      message.error('Ошибка при экспорте: ' + error.message);
    } finally {
      setExporting(false);
    }
  };

  useEffect(() => {
    if (selectedLayer) {
      getLayerCities();
      setSelectedCity();
    }
  }, [selectedLayer]);

  useEffect(() => {
    debouncedSearchLayer(searchText);
  }, [searchText]);

  return (
    <div style={{ padding: '24px' }}>
      <Select
        showSearch
        style={{ width: 550 }}
        value={selectedLayer}
        placeholder={"Название или категорию слоя"}
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={searchText?.length ? undefined : null}
        onChange={setSelectedLayer}
        onSearch={setSeacrhText}
        options={(layers || []).map((layer) => ({
          value: layer.id,
          label: `${layer.category} - ${layer.name}`,
        }))}
      />

      {cities.length ? (
        <>
          <h3 style={{ marginTop: '24px', marginBottom: '16px' }}>Список городов</h3>
          <Tree
            checkable
            style={{ width: 550 }}
            value={selectedCity}
            placeholder={"Выберите город"}
            onCheck={setSelectedCity}
            treeData={cities.map((city) => ({
              key: city.id,
              title: city.name,
            }))}
          />
        </>
      ) : null}

      {selectedCity?.length && (
        <>
          <Button
            onClick={getLayerComponentsByCity}
            type="primary"
            loading={loadingComponents}
          >
            Получить данные
          </Button>
        </>
      )}

      {layerComponents && (<h3 style={{ marginTop: '24px', marginBottom: '16px' }}>Список объектов</h3>
      )}



      {layerComponents ? (
        <div style={{ width: "50%", textAlign: "left", marginLeft: 24 }}>
          <div>
            {layerComponents?.antennas[0]?.count !== "0" ? (
              <Checkbox
                checked={selectedAntennas}
                onChange={(e) => setSelectedAntennas(e.target.checked)}
              >
                Антенны ({layerComponents?.antennas[0]?.count})
              </Checkbox>
            ) : null}
          </div>
          <div>
            {layerComponents?.pipestands[0]?.count !== "0" ? (
              <Checkbox
                checked={selectedPipestands}
                onChange={(e) => setSelectedPipestands(e.target.checked)}
              >
                Трубостоки ({layerComponents?.pipestands[0]?.count})
              </Checkbox>
            ) : null}
          </div>
        </div>
      ) : <></>}

      {layerComponents?.apks?.length > 0 ? (
        <Tree
          checkable
          onCheck={(checkedKeys) => {
            setSelectedApks(checkedKeys);
          }}
          treeData={[
            {
              title: "АПК",
              children: layerComponents?.apks.map((apk) => ({
                title: `${apk.name} (${apk.count})`,
                key: apk.ref_apk_type_id,
              })),
            },
          ]}
        />
      ) : null}

      {layerComponents?.fiberObjects?.length > 0 ? (
        <Tree
          checkable
          onCheck={(checkedKeys) => {
            setSelectedObjects(checkedKeys);
          }}
          treeData={[
            {
              title: "Объекты",
              children: layerComponents?.fiberObjects?.map((fiberObject) => ({
                title: `${fiberObject?.name} (${fiberObject?.count})`,
                key: fiberObject.objectTypeId,
              })),
            },
          ]}
        />
      ) : null}

      {Object.keys(layerComponents?.lines || {})?.length > 0 ? (
        <Tree
          checkable
          onCheck={(checkedKeys) => {
            setSelectedLines(checkedKeys);
          }}
          treeData={[
            {
              title: "Линии",
              children: Object.values(layerComponents?.lines).map((line) => ({
                title: routingTypes.find(type => type.id == line.type)?.name,
                children: line.children.map((type) => ({
                  title: `${type.name} (${type.count})`,
                  key: `${line.type}-${type.cableTypeId}`,
                })),
              })),
            },
          ]}
        />
      ) : null}

      {layerComponents?.baseStations?.length > 0 ? (
        <Tree
          checkable
          onCheck={(checkedKeys) => {
            setSelectedBaseStations(checkedKeys);
          }}
          treeData={[
            {
              title: "Узел связи",
              children: layerComponents?.baseStations.map((bs) => ({
                title: `${baseTypes.find(type => type.id === bs.base_type)?.name} (${bs.count})`,
                key: bs.base_type,
              })),
            },
          ]}
        />
      ) : null}

      {layerComponents && (
        <Button
          type="primary"
          onClick={handleExport}
          style={{ marginTop: 16 }}
          loading={exporting}
        >
          Экспортировать выбранные
        </Button>
      )}
    </div>
  );
};

export default ExportLayerPage;